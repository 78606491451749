import { useQuery } from '@tanstack/react-query'
import { aOrganization, useAtomValue } from '~/atoms'
import { ModelFind, User } from '~/models'

type ApiResponse = {
  success: boolean
  message?: string
}

type FetchUserResponse = ApiResponse & {
  users: User[]
}

async function fetchUsers(find: ModelFind): Promise<FetchUserResponse> {
  const response = await fetch('/api/users', {
    body: JSON.stringify(find),
    method: 'POST',
  })
  const result = await response.json()
  if (!result.success) {
    console.error('Error loading users', result.error)
    throw new Error(`There was a problem fetching results: ${result.error}`)
  }
  return result
}

export function useAdminUsersQuery(key: string[], find?: {}) {
  const organization = useAtomValue(aOrganization)
  const usersQuery = useQuery({
    queryKey: key,
    queryFn: () =>
      fetchUsers({ ...find, 'organization._id': organization!._id }),
    // keepPreviousData: true,
    refetchOnWindowFocus: false,
  })
  return {
    isLoading:
      usersQuery.isLoading ||
      usersQuery.isFetching ||
      usersQuery.isRefetching ||
      usersQuery.isPending,
    isError: usersQuery.isError,
    users: usersQuery.data?.users || [],
  }
}
